import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"

import beeIcon from "../images/bee-icon.png"
import { FaChevronCircleRight } from 'react-icons/fa';

import videoMarketing from "../images/news/article-1/video-marketing.jpg"

const BuzzPage = () => {
    return (
      <Layout location="news">
        <SEO title="News" />
        <div className={ newsStyles.withPosts }>
          <div className={ newsStyles.container }>
            <h1 className={ newsStyles.title }>News</h1>
            <div className={ newsStyles.content }>
              <h4>1 Post</h4>
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={videoMarketing} alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>VIDEOS MAKE YOU VISIBLE!</h3>
                  <h5>November 26, 2020</h5>
                  <p>Adding videos to your marketing communications armada will effectively engage customers and prospects and increase your sales...</p>
                  <Link to="/news/article-1/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );

    // For when there are no articles, replace the code above with this (uncommented, of course):
    // return (
    //   <Layout location="news">
    //     <SEO title="News" />
    //     <div className={ newsStyles.grid }>
    //       <div className={ newsStyles.left }>
    //         <div className={ newsStyles.titleSection }>
    //           <div className={ newsStyles.noNews }>
    //             <img src={ beeIcon } alt=""/>
    //             <h1>Nothing yet...</h1>
    //             <h3>But stay tuned for exciting adventures yet to be told!</h3>
    //             <button onClick={() => {
    //                 window.history.back()
    //             }}>Go Back</button>
    //           </div>
    //         </div>
    //       </div>
    //       <div className={ newsStyles.right }>
    //       </div>
    //     </div>
    //   </Layout>
    // );
}

export default BuzzPage